@font-face {
    font-family: 'moonbold';
    src: url('moonlight.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futurism';
    src: url('./futurism.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

.overlay-container-modules-char {
    display: flex;
    flex-direction: column;  /* Change to column to stack title and line vertically */
    align-items: center;
    color: rgba(0, 0, 0, 0.729);
    padding: 10px;
    width: 100%;
}

.container-modules-char {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;  
}

.title-modules-char {
    font-family: 'Futurism', sans-serif;
    font-size: 155px;
    color: rgba(165, 165, 165, 0.729);
    text-shadow: 2px 2px 4px rgba(251, 249, 249, 0.5);
    margin-left: -20%;
    margin-top: -280px;
    position: relative;
    transform: scaleY(1.8);
}

.overlay-image-modules-char {
    position: absolute;
    top: 9.0%;
    left: 75%;
    transform: translate(-50%, -50%);
    height: 280px;
    width: 280px;
    transition: transform 0.5s ease-in-out;
    animation: float 3s ease-in-out infinite;
}

/* .line-under-title-char {
    width: 62%;
    height: 0.2px;
    background-color: rgb(97, 85, 85);
    margin: 0 auto;
    margin-top: -80px; 
    margin-right: 420px;
} */

@media (max-width: 768px) {
    .overlay-container-modules-char {
        display: flex;
        flex-direction: column;  /* Change to column to stack title and line vertically */
        align-items: center;
        color: rgba(0, 0, 0, 0.729);
        padding: 10px;
        width: 100%;
    }

    .container-modules-char {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: center;  
    }

    .title-modules-char {
        font-family: 'Futurism', sans-serif;
        font-size: 55px;
        color: rgba(165, 165, 165, 0.729);
        text-shadow: 2px 2px 4px rgba(251, 249, 249, 0.5);
        margin-left: -0%;
        margin-top: -320px;
        position: relative;
        transform: scaleY(1.8);
    }

    .overlay-image-modules-char {
        position: absolute;
        top: 16.5%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 120px;
        width: 120px;
        transition: transform 0.5s ease-in-out;
        animation: float 3s ease-in-out infinite;
    }
}
