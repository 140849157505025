/* Home9.css */

@font-face {
    font-family: 'FENOMENO';
    src: url('FENOMENO.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'moonbold';
    src: url('moonlight.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futurism';
    src: url('./futurism.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.home9-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Ensure the container takes up at least the full viewport height */
    background: linear-gradient(to bottom, rgb(35, 35, 35), rgb(0, 0, 0));
    background-image: url('bmabg.jpg');
    background-size: cover;
    background-position: center;

}

.home9-content {
    text-align: center;
    transform: translateY(20px);
    transition: opacity 1s ease-out, transform 1s ease-out;
    position: relative;
    padding-top: 300px; /* Adjust padding to prevent overflow */
    padding-bottom: 50px; /* Add padding at the bottom to create space */
    height: auto; /* Allow content height to adjust based on content */
}

.home9-content::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    height: 100%;
    background-image: url('bridgetlogo.png');
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.1; /* Adjust this value to control how light the background image is */
    z-index: -1; /* Ensure the image is behind the text */
    transform: translate(-50%, -50%); /* Center the logo */
    animation: float 3s ease-in-out infinite;
    
}

.overlay-image.floating {
    animation: float 3s ease-in-out infinite;
  }

.home9-text, .home9-subtext {
    position: relative; /* Make sure text is on top of the background image */
    z-index: 1;
}

.home9-text {
    font-family: 'Futurism', sans-serif;
    font-size: 160px;
    color: #fff;
    margin-bottom: 20px;
    text-shadow: 4px 4px 9px rgba(251, 249, 249, 0.5);
    transition: transform 0.5s ease-in-out, color 0.5s ease-in-out;
}

.home9-subtext {
    font-family: 'moonbold', sans-serif;
    font-size: 1.2rem;
    color: #fff;
}

.highlight9-c {
    font-family: 'Futurism', sans-serif;
    color: #f39c12; /* Orange color for the highlighted letter */
}

.visible9 {
    opacity: 1;
    transform: translateY(0);
}

