/* @font-face {
    font-family: 'moonbold';
    src: url('moonlight.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.overlay-container-modules {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: center;
    height: 20vh;
    overflow: hidden;
    background-color: rgb(13, 13, 14); 
    background-image: url('bg1.jpg');
    background-size: 50% 70%; 
    background-position: center center; 
    background-repeat: no-repeat;
    overflow: hidden; 
    transition: background-size 1s ease-in-out; 
}

.title-modules {
    font-family: 'moonbold', sans-serif; 
    font-size: 158px; 
    margin: 1px 0 20px 0;
    color: rgba(113, 114, 131, 0.729);
    text-shadow: 2px 2px 4px rgba(251, 249, 249, 0.5); 
    margin-top: -20px;
}

.container-modules {
    position: relative;
    width: 100%;  
    height: 100%; 
    display: flex;
    justify-content: flex-start; 
    align-items: center;
    margin-top: -270px; 
    margin-right: -40%; 
}

.overlay-image-modules {
    max-width: 15%; 
    max-height: 50%; 
    margin-left: 20px; 
    margin-top: -85px; 
    padding: 20px; 
    transition: transform 0.5s ease-in-out; 
    animation: float 3s ease-in-out infinite; 
} */
@font-face {
    font-family: 'moonbold';
    src: url('moonlight.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futurism';
    src: url('./futurism.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  

.overlay-container-modules {
    display: flex;
    flex-direction: column;  /* Change to column to stack title and line vertically */
    align-items: center;
    background-color: rgb(5, 5, 5);
    padding: 10px;
    width: 100%;
}

.container-modules {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;  /* Center align title and image */
}

.title-modules {
    font-family: 'Futurism', sans-serif;
    font-size: 170px;
    color: rgba(113, 114, 131, 0.729);
    text-shadow: 2px 2px 4px rgba(251, 249, 249, 0.5);
    margin-left: -20%;
    margin-top: -260px;
    position: relative;
    transform: scaleY(1.8);
}

.overlay-image-modules {
    position: absolute;
    top: 14%;
    left: 73%;
    transform: translate(-50%, -50%);
    height: 240px;
    transition: transform 0.5s ease-in-out;
    animation: float 3s ease-in-out infinite;
}

/* .line-under-title {
    width: 62%;
    height: 0.2px;
    background-color: rgb(30, 30, 31);
    margin: 0 auto;
    margin-top: -80px; 
    margin-right: 420px;
} */






@media (max-width: 768px) {
    .overlay-container-modules {
        display: flex;
        flex-direction: column;  /* Change to column to stack title and line vertically */
        align-items: center;
        background-color: rgb(5, 5, 5);
        padding: 10px;
        width: 100%;
    }

    .container-modules {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: center;  /* Center align title and image */
    }

    .title-modules {
        font-family: 'Futurism', sans-serif;
        font-size: 55px;
        color: rgba(113, 114, 131, 0.729);
        text-shadow: 2px 2px 4px rgba(251, 249, 249, 0.5);
        margin-left: -0%;
        margin-top: -320px;
        position: relative;
        transform: scaleY(1.8);
    }

    .overlay-image-modules {
        position: absolute;
        top: 14%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 130px;
        transition: transform 0.5s ease-in-out;
        animation: float 3s ease-in-out infinite;
    }
}
