.translate-container {
    background-color: black;
    color: white;
    height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .translate-title {
    font-size: 50px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .translate-textarea {
    width: 100%;
    height: 70vh;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    outline: none;
    color: black; /* Text color */
    background-color: white; /* Background color */
  }
  
  .translate-controls {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  
  .translate-dropdown {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    margin-right: 10px;
    background-color: red;
  }
  
  .translate-button {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    background-color: white;
    color: black;
    cursor: pointer;
  }
  