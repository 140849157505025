@font-face {
  font-family: 'Futurism';
  src: url('./futurism.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.hstack-container-char {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 120px; /* Space between each image card */
  padding: 20px;
  max-width: 1200px; /* Increase max width as needed */
  margin: 0 auto; /* Center the container */
  /* background-image: url('./bgnew2.jpg'); /* Add background image */
  /* background-size: cover; Cover the entire container */
  /* background-position: center; Center the background image */ 
}

.image-card-char {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px; /* Increase the width as needed */
  margin: 10px;
  text-align: center;
}

.image-card-char img {
  width: 200px; /* Set a fixed width */
  height: 200px; /* Set a fixed height */
  object-fit: contain; /* Ensure the full image is visible and scaled down */
  transition: transform 0.3s ease;
}

.image-card-char img:hover {
  transform: scale(1.9); /* Zoom effect on hover */
}

.image-label-char {
  margin-top: 10px;
  font-family: 'Futurism', sans-serif;
  font-size: 24px; /* Increase the font size as needed */
  color: white; /* Adjust the color as needed */
}

/* Responsive Design */
@media (max-width: 768px) {
  .hstack-container-char {
    gap: 40px; /* Reduce space between each image card for smaller screens */
  }

  .image-card-char {
    max-width: 45%; /* Adjust the width for smaller screens */
    margin: 5px; /* Reduce margin for smaller screens */
  }

  .image-card-char img {
    width: 100%; /* Adjust the width for smaller screens */
    height: auto; /* Maintain aspect ratio */
  }

  .image-label-char {
    font-size: 18px; /* Adjust the font size for smaller screens */
  }

  .hstack-container-char {
    gap: 10px; /* Reduce space between each image card for smaller screens */
  }

  .image-card-char {
    max-width: 20%; /* Adjust the width for smaller screens */
    margin: 5px; /* Reduce margin for smaller screens */
  }

  .image-card-char img {
    width: 100px; /* Adjust the width for smaller screens */
    height: 100px; /* Adjust the height for smaller screens */
  }

  .image-label-char {
    font-size: 18px; /* Adjust the font size for smaller screens */
  }

  .image-label-char {
    margin-top: 10px;
    font-family: 'Futurism', sans-serif;
    font-size: 11px; /* Increase the font size as needed */
    color: white; /* Adjust the color as needed */
  }
}



