@font-face {
  font-family: 'Futurism';
  src: url('./futurism.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'space';
  src: url('./space.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CG';
  src: url('./CG.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.about-container {
  background-image: url('bgnew9.png'); /* Adjust path as needed */
  background-size: cover;
  background-position: center;
  height: 100vh;
  color: white;
  display: flex;
  justify-content: flex-start; /* Align to the left */
  align-items: center;
  padding: 160px 120px 120px 120px; /* Increase top and bottom padding */
  font-family: 'Futurism', sans-serif;
  font-size: 12px;
  color: rgb(17, 17, 18);
}


.about-content {
  background: rgba(0, 0, 0, 0.6);
  padding: 10px;
  border-radius: 10px;
  width: 100%; /* Cut the width to half */
  max-width: 950px; /* Ensure it doesn't get too wide on larger screens */
  text-align: center;
  opacity: 95%;
  /* background-image: url('bgnew14.jpg'); */
  background-size: cover;
  background-position: center;
  opacity: 89%;
  box-shadow: 0 0 120px 20px rgba(255, 255, 255, 0.99); /* Add glow effect */
  backdrop-filter: blur(10px); /* Add blur effect */
}

.bordered-section {
  border: 1px solid rgb(255, 255, 255); /* Add border */
  padding: 20px; /* Adjust padding for inner spacing */
  margin: 20px 0 40px 0; /* Adjust margin to provide spacing around the bordered section */
  transform: translateY(-20px); /* Move up by 20px */
  /* background-image: url('bgnew17.JPG'); */
  background-color: rgb(0, 0, 0); /* Add semi-transparent background color */
  background-size: cover; /* Ensure the image covers the entire section */
  background-position: center; /* Center the background image */
  border-radius: 15px; /* Round the borders */
  box-shadow: 0 0 15px rgba(255, 221, 0, 0.5); /* Add glow effect */
  margin-top: 20px;
  margin-bottom: -10px;
}


.about-content h1 {
  font-family: 'Futurism', sans-serif;
  font-size: 120px;
  color: rgba(12, 12, 12, 0.871);
  margin-bottom: -20px;
  color: rgba(0, 0, 0, 0.581);
  text-shadow: 2px 2px 4px rgba(251, 249, 249, 0.5);
  transform: scaleY(1.5);
}

.about-content p {
  font-size: 37px;
  /* margin-top: -60px; */
  font-family: 'futurism', sans-serif;
  color: rgba(252, 252, 252, 0.965);
  /* margin-top: 20px; */
  /* margin-bottom: 10px; */
  font-weight: bold;
  /* text-shadow: 0 0 10px rgba(18, 128, 252, 0.8);  */
  /* font-style: italic; */
  transform: scaleY(.5);
}

.features-list {
  font-size: 30px;
  margin-top: 5px;
  margin-bottom: -10px;
  font-family: 'space', sans-serif;
  color: rgba(255, 255, 255, 0.952); 
  list-style-type: none;
  text-align: center;
  font-weight: bold;
  text-shadow: 0 0 5px rgba(244, 232, 9, 0.8); 
}


.features-list li {
  margin-bottom: -10px;
}

.carousel-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel-item h2 {
  font-size: 2em;
  margin-bottom: 10px;
  font-family: 'CG', sans-serif;
  font-size: 40px;
  color: rgba(222, 222, 222, 0.871);
}

.carousel-item h4 {
  font-size: 1.2em;
  margin-bottom: 20px;
  font-family: 'CG', sans-serif;
}

.carousel-video {
  width: 80%;
  border: 2px solid white;
  border-radius: 10px;
}

.carousel .slide {
  background: none;
}

/* Media Queries for smaller screens */
@media (max-width: 768px) {
  .about-container {
    background-image: url('bgnew9.png'); /* Adjust path as needed */
    background-size: cover; /* Stretch the background image to cover the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    min-height: 100vh; /* Ensure the container takes at least the full height of the viewport */
    color: white;
    display: flex;
    align-items: center;
    padding: 160px 120px 120px 120px; /* Increase top and bottom padding */
    font-family: 'Futurism', sans-serif;
    font-size: 12px;
    color: rgb(17, 17, 18);
  }
  

  .about-content {
    padding: 50px;
    width: 80%; /* Adjust width for smaller screens */
    max-width: 400px; /* Ensure it doesn't get too wide on larger screens */
  }

  .about-content h1 {
    font-size: 60px; /* Adjust title size for smaller screens */
  }

  .about-content p,
  .features-list {
    font-size: 1.2em; /* Adjust text size for smaller screens */
  }

  .carousel-item h2 {
    font-size: 1.5em; /* Adjust subtitle size for smaller screens */
  }

  .carousel-item h4 {
    font-size: 1em; /* Adjust subtitle size for smaller screens */
  }
}

@media (max-width: 768px) {
  .about-container {
    justify-content: center; /* Center the content */
    
  }

  .about-content {
    /* padding: 20px; */
    max-width: 59%;
    width: 70%;
    margin-left: 175px;
    text-align: center; /* Ensure text is centered */
  }

  .about-content h1 {
    font-size: 40px; /* Adjust title size for smaller screens */
    margin-bottom: 10px; /* Add margin below title */
    transform: scaleY(2.5);
  }

  

  .about-content p {
    font-size: 0.8em; /* Adjust text size for smaller screens */
  }

  .features-list {
    font-size: 0.8em; /* Adjust text size for smaller screens */
    margin-top: 10px; /* Adjust margin */
  }

  .features-list li {
    margin-bottom: 5px; /* Reduce space between list items */
  }

  .bordered-section {
    border: 1px solid rgb(255, 255, 255); /* Add border */
    padding: 10px; /* Adjust padding for inner spacing */
    margin: 10px 0 20px 0; /* Adjust margin to provide spacing around the bordered section */
    transform: translateY(0); /* Remove vertical translation */
    background-color: rgba(0, 0, 0, 0.8); /* Add semi-transparent background color */
    border-radius: 10px; /* Round the borders */
    box-shadow: 0 0 10px rgba(255, 221, 0, 0.5); /* Add glow effect */
  }

  .carousel-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .carousel-item h2 {
    font-size: 1.2em; /* Adjust subtitle size for smaller screens */
    margin-bottom: 5px; /* Adjust margin */
  }

  .carousel-item h4 {
    font-size: 1em; /* Adjust subtitle size for smaller screens */
    margin-bottom: 10px; /* Adjust margin */
  }

  .carousel-video {
    width: 80%; /* Adjust video width for smaller screens */
    border: 2px solid white;
    border-radius: 10px;
  }

  .carousel .slide {
    background: none;
  }
}
