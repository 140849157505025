/* Home2.css */

@font-face {
    font-family: 'FENOMENO';
    src: url('FENOMENO.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'moonbold';
    src: url('moonlight.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futurism';
    src: url('./futurism.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.home2-container {
    height: 100vh;
    min-height: 100vh;
    padding-top: 120px; /* Adjust this value to move the content closer to the top */
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, #1e1e1e, #010102);
    flex-direction: column;
}

.home2-content {
    opacity: 0;
    transform: translateY(80%);
    transition: opacity 1.2s cubic-bezier(0.25, 1, 0.5, 1), transform 1.2s cubic-bezier(0.25, 1, 0.5, 1);
    text-align: center;
}

.home2-content.visible {
    opacity: 1;
    transform: translateY(-10%);
    transition: opacity 1.2s ease-out, transform 1.2s ease-out;
    pointer-events: none; /* Prevent any further interaction causing the animation to restart */
}

.home2-text {
    color: white;
    font-family: 'Futurism', sans-serif;
    font-size: 155px;
    text-shadow: 4px 4px 9px rgba(251, 249, 249, 0.5);
    box-shadow: 10px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.home2-subtext {
    color: rgba(225, 225, 225, 0.852);
    font-family: 'moonbold', sans-serif;
    font-size: 37px;
    max-width: 98%;
    margin: 0 auto;
    opacity: 0;
    line-height: 1.6;
    transform: translateY(20%) scale(0.84);
    transition: opacity 1s ease-out 1s, transform 1.2s cubic-bezier(0.25, 0.8, 0.25, 1);
    text-shadow: 4px 4px 9px rgba(251, 249, 249, 0.5);
    box-shadow: 10px 4px 8px rgba(0, 0, 0, 0.1);
}

.home2-content.visible .home2-subtext {
    opacity: 0.8;
    transform: translateY(0) scale(1);
    font-size: 44px;
}

.highlight-b {
    font-size: 60px;
    line-height: 1;
    vertical-align: middle;
}
