/* HamburgerMenu.css */

@font-face {
    font-family: 'Futurism';
    src: url('futurism.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.hamburger-menu {
    position: relative;
}

.hamburger-icon {
    position: fixed;
    top: 20px;
    left: 20px;
    width: 30px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    z-index: 10000;
}

.hamburger-icon .bar {
    width: 100%;
    height: 4px;
    background-color: rgb(49, 50, 58);
    transition: all 0.3s ease;
}

.hamburger-icon.open .bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.hamburger-icon.open .bar:nth-child(2) {
    opacity: 0;
}

.hamburger-icon.open .bar:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
}

.menu {
    font-family: 'Futurism', sans-serif;
    position: fixed;
    top: 0;
    left: -250px;
    width: 270px;
    height: 100vh;
    background-color: rgba(74, 61, 61, 0.1);
    transition: left 0.3s ease;
    z-index: 9999;
}

.menu.open {
    left: 0;
}

.menu ul {
    list-style-type: none;
    padding: 60px;
    margin: 0;
}

.menu ul li {
    margin-bottom: 20px;
}

.menu ul li a {
    font-family: 'Futurism', sans-serif;
    text-decoration: none;
    font-size: 18px;
    margin: 1px 0 20px 0;
    color: rgba(254, 254, 254, 0.729);
    font-weight: bold;
}
