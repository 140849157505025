@font-face {
    font-family: 'Cyber';
    src: url('./Cyber.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futurism';
    src: url('futurism.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.product-grid-wrapper {
    padding-top: 0px; /* Adjust this value to move the cards up or down */
    width: 100%;
    max-width: 1200px; /* Adjust this value as needed */
    padding: 20px;
    margin: 0 auto; /* Center the grid container */
}


.card-container {
    padding-top: 5px; /* Reduce this value to move the cards up */
    background-image: url('bmabg.jpg');
    background-size: cover;
    background-position: center;
    padding: 20px;
    color: #fff;
    font-family: 'Cyber', sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.5s ease-in-out, box-shadow 0.3s ease;
    border-radius: 15px;
    border: 5px solid rgba(3, 154, 254, 0.8); 
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.card-container:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 15px rgba(3, 154, 254, 0.8); /* Additional shadow effect on hover */
}

.card-heading {
    font-family: 'Cyber', sans-serif !important;
    font-size: 25px;
    color: #fff;
    margin-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 15px;
}

.card-text {
    font-family: 'Cyber', sans-serif;
    font-size: 40px;
    color: #fff;
    margin-bottom: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
}

.card-footer {
    margin-top: auto;
    display: flex;
    justify-content: center; /* Center the button horizontally */
    padding-top: 10px;
    position: relative;
    z-index: 1;
}

.custom-button {
    background-color: #000000fe !important;
    color: #f3e006;
    border: none;
    padding: 10px 20px;
    font-family: 'Cyber', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 1 !important;
    display: inline-block;
    position: relative; /* Ensure it's positioned correctly */
}

.custom-button:hover {
    background-color: #ff6347;
}

/* Ensure the image is styled correctly */
.card-image {
    width: 100%; /* Ensures the image takes up the full width of the card */
    height: auto; /* Maintains the aspect ratio of the image */
    border-radius: 5px; /* Optional: adds rounded corners to the image */
    display: block;
    margin: 0 auto;
}

/* Add this new class for the shorter image */
.short-image {
    width: 50%; /* Ensures the image takes up the full width of the card */
    height: 200px; /* Adjust the height to make it shorter */
    object-fit: cover; /* Ensures the image covers the entire area without distortion */
    border-radius: 5px; /* Optional: adds rounded corners to the image */
    display: block;
    margin: 0 auto;
}
