.home4-container {
    min-height: 110vh; /* Increase the height slightly */
    padding-top: 160px; /* Increase padding to move content further down */
    display: flex;
    justify-content: flex-end; /* Align items to the bottom */
    align-items: center;
    background: linear-gradient(to bottom, #000000, #0c0a0a);
    flex-direction: column;
    text-align: center;
    padding: 20px;
}

.home4-content {
    opacity: 0;
    transform: translateY(100%); /* Keep the initial position as it is */
    transition: opacity 1.2s cubic-bezier(0.25, 1, 0.5, 1), transform 1.2s cubic-bezier(0.25, 1, 0.5, 1);
    margin-bottom: -60px; /* Adjust margin to control spacing from the bottom */
}

.home4-content.visible {
    opacity: 1;
    transform: translateY(-10%);
    transition: opacity 1.2s ease-out, transform 1.2s ease-out;
    pointer-events: none;
}

.home4-text {
    color: white;
    font-family: 'Futurism', sans-serif;
    font-size: 135px;
    text-shadow: 4px 4px 9px rgba(251, 249, 249, 0.5);
    box-shadow: 10px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.home4-subtext {
    color: rgba(225, 225, 225, 0.852);
    font-family: 'moonbold', sans-serif;
    font-size: 37px;
    max-width: 98%;
    margin: 0 auto 20px;
    opacity: 0;
    line-height: 1.6;
    transform: translateY(20%) scale(0.84);
    transition: opacity 1s ease-out, transform 1.2s cubic-bezier(0.25, 0.8, 0.25, 1);
    text-shadow: 4px 4px 9px rgba(251, 249, 249, 0.5);
    box-shadow: 10px 4px 8px rgba(0, 0, 0, 0.1);
}

.home4-content.visible .home4-subtext {
    opacity: 0.8;
    transform: translateY(0) scale(1);
    font-size: 42px;
}

.home4-image {
    width: 80%;
    height: auto;
    margin: 0 auto;
    opacity: 0;
    transition: opacity 1.5s ease-out;
}

.home4-content.visible .home4-image {
    opacity: 1;
}
