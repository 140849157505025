/* Main container settings */
.home7-container {
    min-height: 100vh;
    padding-top: 120px; /* Adjust this value to move the content closer to the top */
    display: block;
    background: linear-gradient(to bottom, #1a1a1a, #c3c3c3);
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    max-width: 100vw; /* Prevents the container from exceeding the viewport width */
    overflow-x: hidden; /* Ensures that no content can overflow horizontally */
    box-sizing: border-box; /* Includes padding in the element's width and height */
    position: relative;
}

/* Content transitions and alignment */
.home7-content {
    width: 100%;
    max-width: 1200px;
    opacity: 0;
    transition: opacity 1.2s ease-out;
    margin: 0 auto; /* Center content within the container */
    overflow: hidden; /* Prevent any internal overflow */
    box-sizing: border-box; /* Includes padding in width calculations */
}

.home7-content.visible {
    opacity: 1;
}

/* Title text styling and animation */
.home7-text {
    color: white;
    font-family: 'Futurism', sans-serif;
    font-size: 80px;
    text-shadow: 4px 4px 9px rgba(251, 249, 249, 0.5);
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    box-sizing: border-box;
}

.text-part7 {
    opacity: 0;
    transition: opacity 1.2s ease-out, transform 1.2s ease-out;
}

.text-part7.left {
    transform: translateX(-100%);
}

.text-part7.right {
    transform: translateX(100%);
}

.home7-content.visible .text-part7 {
    opacity: 1;
    transform: translateX(0);
}

/* Subtext container */
.home7-subtext {
    color: #2a2323d9;
    font-family: 'moonbold', sans-serif;
    font-size: 24px;
    line-height: 1.6;
    width: 80%;
    margin-left: 4%;
    padding-right: 10%;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 1.5s ease-out, transform 1.5s ease-out;
    box-sizing: border-box;
}

.home7-content.visible .home7-subtext {
    opacity: 1;
    transform: translateY(0);
}

/* Image container */
.home7-image {
    position: absolute;
    right: -10px;
    top: 20%;
    transform: translateY(-50%);
    height: auto;
    opacity: 0;
    transition: opacity 1.0s ease-out 1.0s, transform 1.0s ease-out 1.0s;
    max-width: 100%; /* Prevents the image from overflowing its container */
    box-sizing: border-box;
}

.home7-content.visible .home7-image {
    opacity: 1;
    transform: translateY(0);
}

.home7-subtext p {
    margin: 10px 0;
    line-height: 1.6;
}
