/* Main container settings */
.home5-container {
    min-height: 100vh;
    padding-top: 120px; /* Adjust this value to move the content closer to the top */
    display: block; /* Change back to block to avoid affecting text alignment */
    background: linear-gradient(to bottom, #1a1a1a,  #ffffff);
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    max-width: 100vw; /* Prevent the container from exceeding the viewport width */
    position: relative; /* Needed for absolute positioning of the image */
    overflow: hidden; /* Prevent overflow beyond the component */
    box-sizing: border-box; /* Include padding in the width calculation */
}

/* Content transitions and alignment */
.home5-content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto; /* Center content within the container */
    opacity: 0;
    transition: opacity 1.2s ease-out;
    overflow: hidden; /* Prevent any internal overflow */
    box-sizing: border-box; /* Includes padding in width calculations */
}

.home5-content.visible {
    opacity: 1;
}

/* Title text styling and animation */
.home5-text {
    color: white;
    font-family: 'Futurism', sans-serif;
    font-size: 80px;
    text-shadow: 4px 4px 9px rgba(251, 249, 249, 0.5);
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    overflow: hidden; /* Ensure text doesn't overflow */
    box-sizing: border-box; /* Include padding in width calculations */
}

.text-part {
    opacity: 0;
    transition: opacity 1.2s ease-out, transform 1.2s ease-out;
}

.text-part.left {
    transform: translateX(-100%); /* Animation starting from the left */
}

.text-part.right {
    transform: translateX(100%); /* Animation starting from the right */
}

.home5-content.visible .text-part {
    opacity: 1;
    transform: translateX(0); /* Reset position to natural alignment */
}

/* Subtext container */
.home5-subtext {
    color: #090808d9;
    font-family: 'moonbold', sans-serif;
    font-size: 24px;
    line-height: 1.6;
    width: 80%; /* Adjust as necessary to match design */
    margin-left: 4%; /* Adjust this to move the subtext */
    padding-right: 10%; /* Optional: space to the right of the subtext */
    opacity: 0;
    transform: translateY(100%); /* Start off-screen at the bottom */
    transition: opacity 1.5s ease-out, transform 1.5s ease-out; /* Animation properties */
    overflow: hidden; /* Ensure subtext doesn't overflow */
    box-sizing: border-box; /* Include padding in width calculations */
}

.home5-content.visible .home5-subtext {
    opacity: 1;
    transform: translateY(0); /* Move to natural position */
}

/* Image container */
.home5-image {
    position: absolute; /* Absolutely positioned */
    right: -50px; /* Positioned further to the right */
    top: 10%; /* Adjusted this value to move the image up */
    transform: translateY(-50%); /* Center vertically relative to the new 'top' */
    height: auto;
    max-width: 100%; /* Prevent the image from exceeding the container's width */
    opacity: 0;
    transition: opacity 1.0s ease-out 1.0s, transform 1.5s ease-out 1.0s; /* Delayed start for sequential animation */
    box-sizing: border-box; /* Ensure the image respects its container's padding */
}

.home5-content.visible .home5-image {
    opacity: 1;
    transform: translateY(0); /* Move to natural position */
}

.home5-subtext p {
    margin: 10px 0; /* Adjust the vertical spacing between paragraphs */
    line-height: 1.6; /* Adjust the line height if needed */
}
