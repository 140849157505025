@font-face {
    font-family: 'moonbold';
    src: url('moonlight.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futurism';
    src: url('./futurism.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  .overlay-container-home {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    background-color: rgb(0, 0, 0);
    background-image: url('bmabg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: background-size 1s ease-in-out;
  }
  
  .title-home {
    font-family: 'Futurism', sans-serif;
    font-size: 218px;
    margin: -20px 2px 90px 0;
    color: rgba(255, 255, 255, 0.729);
    text-shadow: 4px 4px 9px rgba(251, 249, 249, 0.5);
    transition: transform 0.5s ease-in-out, color 0.5s ease-in-out;
  }
  
  .title-home:hover {
    transform: scale(1.05);
    color: rgba(255, 255, 255, 0.915);
  }
  
  .container-home {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .overlay-image {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 95%;
    max-height: 90%;
    padding: 20px;
    transition: left 0.5s ease-in-out;
    z-index: 999;
    cursor: pointer;
  }
  
  .overlay-image.floating {
    animation: float 3s ease-in-out infinite;
  }
  
  .overlay-image.moved {
    left: calc(100% - 20px);
    transform: translate(-50%, -50%);
    animation: none;
  }
  
  .carousel-container {
    position: absolute;
    top: 30%;
    left: 26%;
    transform: translateY(-50%);
    width: 50%; /* Adjust width as needed */
    z-index: 1000;
    border-radius: 15px; /* Round the corners */
    transition: opacity 3s ease-in-out, transform 3s ease-in-out;
    opacity: 0; /* Initial state: invisible */
  }

  .carousel-container.visible {
    opacity: 1; /* Visible state */
  }
  
  .carousel-container img {
    max-width: 1000px; /* Ensure the image does not exceed the container's width */
    max-height: 900px; /* Adjust the height as needed */
    object-fit: contain; /* Maintain aspect ratio */
    border-radius: 15px; /* Round the corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    transition: transform 1.0s ease-in-out, box-shadow 0.5s ease-in-out; /* Smooth transition */
  }

  .carousel-container img:hover {
    transform: scale(1.02); /* Slightly enlarge the image on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
    border-radius: 15px; /* Round the corners */

  }

  .react-multiple-carousel__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(33, 30, 42, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1001;
    transition: background-color 0.3s ease;
  }
  
  .react-multiple-carousel__arrow:hover {
    background-color: rgba(42, 35, 35, 0.8);
  }
  
  .react-multiple-carousel__arrow:focus {
    outline: none;
  }
  
  .react-multiple-carousel__arrow--left {
    left: 0; /* Adjust positioning as needed */
  }
  
  .react-multiple-carousel__arrow--right {
    right: 10px; /* Adjust positioning as needed */
  }
  
  @keyframes float {
    0%, 100% {
      transform: translate(-50%, -50%);
    }
    50% {
      transform: translate(-50%, -53%);
    }
  }
  
  @media (max-width: 768px) {
    .overlay-image {
      max-width: 80%;
      max-height: 35%;
    }
  
    .carousel-container {
      width: 80%;
    }
  }
  

  @media (max-width: 768px) {
    .overlay-container-home {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-size: cover; /* Make the background image slimmer */
        background-position: center center; /* Center the background image */
        background-attachment: fixed; /* Fix the background image in place */
    }

    .carousel-container {
        top: -10%; /* Centralize vertically */
        left: 45%; /* Centralize horizontally */
        transform: translate(-50%, -50%); /* Adjust transformation to center */
        width: 90%; /* Make it wider to use more space */
        opacity: 1; /* Make it always visible */
        position: relative;
        border-radius: 15px; /* Round the corners */
        transition: opacity 3s ease-in-out, transform 3s ease-in-out;
    }

    .carousel-container img {
        max-width: 400px; /* Smaller image width for 768px view */
        max-height: 450px; /* Smaller image height for 768px view */
        object-fit: contain; /* Maintain aspect ratio */
        border-radius: 15px; /* Round the corners */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19); /* Add box shadow */
        transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, filter 0.5s ease-in-out;
        position: relative;
    }

    .carousel-container img:hover {
        transform: scale(1.05); /* Slightly enlarge the image on hover */
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Enhance shadow on hover */
    }

    .title-home {
        font-size: 68px; /* Adjusted for smaller screens */
        margin: 100px 2px 150px 0; /* Reduced margin */
        text-shadow: 2px 3px 5px rgba(251, 249, 249, 0.913);
        display: inline-block; /* Ensure the transform property works properly */
        transform: scaleY(1.8); /* Make the font taller */
        transform-origin: center;
    }

    .container-home {
        margin-top: 10px; /* Reduced margin */
    }

    .overlay-image {
        top: 20%; /* Adjusted for better visibility */
        max-width: 90%; /* Increased to use more of the screen */
        max-height: 80%; /* Adjusted to not overlap too much */
    }

    .react-multiple-carousel__arrow--left {
        left: 5px; /* Closer to the edge */
    }

    .react-multiple-carousel__arrow--right {
        right: 5px; /* Closer to the edge */
    }
}


/* @media (max-width: 480px) {
    .title-home {
        font-size: 75px; 
        margin: 5px 1px 30px 0;
    }

    .overlay-image {
        max-width: 100%; 
        max-height: 40%; 
    }
} */

@media (max-width: 414px) {
  .title-home {
    font-size: 70px;
    margin: 5px 1px 30px 0;
    display: inline-block; /* Ensure the transform property works properly */
    transform: scaleY(1.3); /* Make the font taller */
    transform-origin: center;
  }
  .overlay-image {
    /* max-width: 100%; 
    max-height: 20%;  */
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 95%;
    max-height: 90%;

}
}

