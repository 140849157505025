/* FooterComponent.css */

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20vh; /* Set to 20% of the display height */
    background-color: #131313; /* Adjust the background color as needed */
    padding: 20px;
  }
  
  .footer-left {
    flex: 1;
    display: flex;
    align-items: center;
  }
  
  .footer-logo {
    width: 190px; /* Adjust the size of the logo as needed */
    height: auto;
  }
  
  .footer-middle {
    flex: 2;
    text-align: center;
  }
  
  .footer-text {
    color: #ffffff;
    margin-bottom: 10px;
  }
  
  .footer-form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .footer-input {
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 10px;
    width: 250px; /* Adjust the width as needed */
  }
  
  .footer-button {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    background-color: #ffd700; /* Adjust the button color as needed */
    border: none;
    color: #1a1a1a;
    cursor: pointer;
  }
  
  .footer-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .footer-link {
    color: #ffffff;
    margin-bottom: 10px;
    text-decoration: none;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  