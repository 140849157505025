/* Main container settings */
.home6-container {
    min-height: 100vh;
    padding-top: 120px; /* Adjust this value to move the content closer to the top */
    padding-bottom: 80px; /* Adjusted padding to reduce spacing from the bottom */
    background: linear-gradient(to bottom, #ffffff, #1a1a1a);
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    max-width: 100vw;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

/* Content positioning */
.home6-content {
    display: flex; /* Flexbox for horizontal alignment */
    align-items: center; /* Vertically center the content */
    width: 100%;
    max-width: 1200px;
    position: relative;
    margin: 0 auto;
    opacity: 0;
    transition: opacity 1.2s ease-out;
    overflow: hidden;
    box-sizing: border-box;
}

.home6-content.visible {
    opacity: 1;
}

/* Image container for stacking images vertically */
.home6-image-container {
    display: flex;
    flex-direction: column; /* Stack images vertically */
    align-items: center;
    flex: 0 0 45%; /* Image container takes 45% of the width */
    max-width: 45%;
    box-sizing: border-box;
}

.home6-image {
    width: 100%;
    height: auto;
    opacity: 0;
    transition: opacity 1.0s ease-out 1.0s, transform 1.0s ease-out 1.0s;
    box-sizing: border-box;
    margin-bottom: 0px; /* Space between the two images */
}

.home6-image2 {
    width: 100%;
    height: auto;
    opacity: 0;
    transition: opacity 1.0s ease-out 1.0s, transform 1.0s ease-out 1.0s;
    box-sizing: border-box;
}

.home6-content.visible .home6-image,
.home6-content.visible .home6-image2 {
    opacity: 1;
}

/* Text container (Title and Subtext) */
.home6-text-container {
    flex: 1; /* Take the remaining width */
    padding-left: 20px; /* Spacing between image and text */
    text-align: right; /* Align all text to the right */
}

/* Title text styling and animation */
.home6-text {
    color: white;
    font-family: 'Futurism', sans-serif;
    font-size: 80px;
    text-shadow: 4px 4px 9px rgba(251, 249, 249, 0.5);
    margin-bottom: 20px;
    overflow: hidden;
    box-sizing: border-box;
}

.home6-text-part {
    opacity: 0;
    display: block;
    transition: opacity 1.2s ease-out, transform 1.2s ease-out;
}

.home6-text-part.left {
    transform: translateX(-100%);
}

.home6-text-part.right {
    transform: translateX(100%);
}

.home6-content.visible .home6-text-part {
    opacity: 1;
    transform: translateX(0);
}

/* Subtext styling */
.home6-subtext {
    color: #0d0d0dd9;
    font-family: 'moonbold', sans-serif;
    font-size: 24px;
    line-height: 1.6;
    text-align: right;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 1.5s ease-out, transform 1.5s ease-out;
    overflow: hidden;
    box-sizing: border-box;
}

.home6-content.visible .home6-subtext {
    opacity: 1;
    transform: translateY(0);
}

.home6-subtext p {
    margin: 10px 0;
    line-height: 1.6;
}

/* Enlarge the first letter of each paragraph */
.home6-subtext p::first-letter {
    font-size: 40px; /* Adjust the size as needed */
    font-weight: bold;
    color: #100f0f;
}
