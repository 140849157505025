/* Home3.css */

@font-face {
    font-family: 'FENOMENO';
    src: url('FENOMENO.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'moonbold';
    src: url('moonlight.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futurism';
    src: url('./futurism.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

  /* Main container settings */
.home8-container {
    min-height: 100vh;
    padding: 120px 20px 80px 20px;
    background: linear-gradient(to bottom, rgb(9, 9, 9), rgb(33, 33, 33));
    width: 100%;
    max-width: 100vw;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  /* Content positioning */
  .home8-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 30px; /* Increased padding to create more space on the left */
    opacity: 1; /* Set initial opacity to 1 */
    transition: opacity 1.2s ease-out;
    border: 5px solid rgba(3, 154, 254, 0.8); /* Blue rounded border around the content */
    border-radius: 15px;
    box-shadow: 0px 0px 15px rgba(3, 154, 254, 0.8); /* Shadow around the border */
    background: inherit; /* Ensure the background is consistent */
    background-color: #1a1a1a; /* Add a fallback background color */
    background-image: url('bmabg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  
  .home8-content.visible {
    opacity: 1; /* Ensure it stays visible */
  }
  
  /* Subtext container styling */
  .home8-subtext-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 40px;
    width: 100%;
  }
  
  /* Subtext styling */
  .home8-subtext {
    flex: 0 0 60%; /* Increase the width of the left side */
    color: rgba(225, 225, 225, 0.85);
    font-family: 'moonbold', sans-serif;
    font-size: 37px;
    line-height: 1.6;
    text-shadow: 4px 4px 9px rgb(0, 0, 0);
    box-shadow: 10px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    margin-right: 20px;
    padding-left: 20px; /* Added padding to create space between the text and the border */
  }
  
  /* Highlighted letter in the main subtext */
  .highlight-c8 {
    font-family: '', sans-serif;
    text-shadow: 4px 4px 9px rgba(251, 249, 249, 0.5);
    font-size: 260px;
    color: #0086e5;
  }
  
  /* Kid and clips container */
  .home8-kid-and-clips {
    position: relative;
    display: inline-block;
  }
  
  /* Kid image styling */
  .home8-kid-image {
    width: 500px;
    height: 500px;
    border-radius: 50%;
    object-fit: cover;
    transition: opacity 1.0s ease-out 1.0s, transform 1.0s ease-out 1.0s;
    opacity: 0;
    z-index: 1;
    box-shadow: 10px 4px 18px rgb(3, 154, 254);
  }
  
  .home8-content.visible .home8-kid-image {
    opacity: 1;
    transform: translateY(50px); /* Pushing the image up */
  }
  
  /* Clips image styling */
  .home8-clips-image {
    position: absolute;
    bottom: -120px; /* Pushing the clips image up */
    left: 0%;
    transform: translateX(-50%);
    width: 100%;
    height: auto;
    transition: opacity 1.0s ease-out 1.0s, transform 1.0s ease-out 1.0s;
    opacity: 0;
    z-index: 0;
  }
  
  .home8-content.visible .home8-clips-image {
    opacity: 1;
    transform: translateY(50px); /* Adjusted to move up with the kid image */
  }
  
  /* Bottom section styling */
  .home8-bottom-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px; /* Reduced margin to move the section up */
  }
  
  /* Phone image styling */
  .home8-phone-image {
    width: 400px; /* Increased size for the phone image */
    height: auto;
    margin-right: 20px;
    transform: translateY(-100px); /* Moved the phone image up */
  }
  
  /* Text container for the additional subtext */
  .home8-text-container {
    flex: 0 0 60%; /* Increased width of the right side */
    text-align: right;
    padding: 20px;
    background: linear-gradient(to bottom, #000000, #000000);
    border-radius: 10px;
    box-shadow: 10px 4px 18px rgb(3, 154, 254);
    margin-left: 100px; /* Adjust margin to keep it balanced after widening */
    transform: translateY(-90px); /* Moved the subtext box up */
    background-image: url('bmabg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  
  /* Title styling */
  .home8-title {
    font-family: '', sans-serif; /* Use Futurism font */
    font-size: 28px;
    color: #c7dbf5;
    margin-bottom: 15px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Add some shadow for depth */
  }
  
  /* Subtext styling */
  .home8-text-container p {
    margin: 10px 0;
    font-size: 24px;
    color: #ffffff;
    line-height: 1.6;
  }
  
  .home8-text-container p:first-letter {
    font-size: 40px;
    font-weight: bold;
    color: #c7dbf5;
  }
  