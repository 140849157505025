/* Home8.css */

.home8a-container {
    background: linear-gradient(to bottom, rgb(78, 78, 78), rgb(0, 0, 0));
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh; /* Increased from 20% to 30% of the display height */
    padding-top: 15vh; /* Adjusted top padding to push the content down */
    padding-bottom: 40px;
}

.home8a-text {
    color: rgb(255, 255, 255);
    font-family: 'Futurism', sans-serif;
    font-size: 133px;
    text-shadow: 4px 4px 9px rgb(5, 5, 5);
    /* box-shadow: 10px 4px 8px rgba(0, 0, 0, 0.1); */
    width: 98%; /* Reduces the width of the text */
    text-align: center; /* Centers the text within the available width */
}
